import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';

import {
    Navbar,
    Footer,
    Contact,
    ProjectStack,
    ProjectsCloud,
    projects,
    Header,
    Section,
} from '../components';

export interface blogideasProps {}

export const blogideas: React.FC<blogideasProps> = props => {
    return (
        <main className="">
            <Helmet>
                <title>BlogIdeas - B. Jasulan</title>
            </Helmet>
            <Navbar />
            <Section>
                <Header align="left">BlogIdeas</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        BlogIdeas is a SEO tool which helps bloggers find ideas
                        for a blog-post using the "Alphabet Soup" Google search
                        technique. It relies heavily on scrapping Google's
                        autosuggestion box automatically putting letters from
                        alphabet to expand on the original keyword. This
                        technique is used to find keywords which are searched by
                        real users.
                    </p>
                    <ProjectStack
                        type="Personal Project"
                        stack={[
                            'TypeScript',
                            'Nest.js (Node)',
                            'Socket.io',
                            'React',
                            'Redux',
                            'D3',
                            'TailwindCSS',
                            'NX',
                            'Cheerio',
                            'Playwright',
                        ]}
                        repository="https://git.bjasulan.com/blogideas/blogideas"
                        website="https://blogideas.xyz"
                    />
                </div>
                <div className="relative">
                    <StaticImage
                        className="inline-block rounded-lg shadow-xl"
                        alt="Google autosuggestion box"
                        src="../images/blogideas.png"
                    />
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Project Purpose and Goals</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        In this project I needed to work on both backend and
                        frontend. The backend was needed to scrap things like
                        search engine results, Google trends, and create a blog
                        post planner. To make it easier to work on both of these
                        things, I decided to keep the project's code in one
                        monorepo using NX.
                    </p>
                    <p className="mt-8">
                        To lazily load the scrapped data into Redux in
                        real-time, I used Socket.io. Another challenge was
                        persisting user-data after the webapp is reloaded. To
                        accomplish that, I used the localStorage.
                    </p>
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Technology Stack</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        In order to scrap keywords from Google, I used
                        Playwright and Cheerio. When it comes to backend Node.js
                        app, I prefer using Nest.js because it uses TypeScript
                        by default and decorators similar to Angular's. For the
                        web-frontend, unless I have to use something different,
                        I always use React and Redux.
                    </p>
                    <p className="mt-8">
                        React hooks, Redux, and local storage is used to persist
                        data whilst Websockets are used to send and receive data
                        from the server.
                    </p>
                </div>
            </Section>
            <Section className="py-14 md:py-16 mt-3">
                <Header align="left">Problems and Thought Process</Header>
                <div className="max-w-4xl text-base leading-8">
                    <p>
                        Whenever I work on a project where I need to work both
                        on frontend and backend code, I usually choose to keep
                        the code in a mono-repo. The easiest tool right now for
                        this is NX. If a project's code-base is medium to big,
                        planning how it should be structured is as important as
                        the code itself.
                    </p>
                    <p className="mt-8">
                        One of the biggest challenges of this project was
                        keeping the server from being flooded with
                        scrap-requests. To solve this problem, I used a library
                        for task-scheduling and rate limiting called Bottleneck.
                    </p>
                </div>
                <div className="mt-16">
                    <StaticImage
                        className="rounded-lg shadow-xl"
                        alt="BlogIdeas"
                        src="../images/search-analysis.png"
                    />
                </div>
            </Section>
            <Section
                header="Lessons Learned"
                className="pt-14 md:pt-16 pb-32 mt-3"
            >
                <div className="mx-auto max-w-4xl text-base leading-8">
                    <p>
                        There were many skills I refreshed doing this project.
                        But the biggest lesson I learned was the importance of
                        task-scheduling and rate limiting heavy user requests.
                        It makes a lot of sense to schedule non-mission critical
                        heavy tasks for later.
                    </p>
                    <p className="mt-8"></p>
                </div>
            </Section>
            <div className="mb-16">
                <ProjectsCloud active={projects[1]} />
            </div>
            <Contact />
            <Footer />
        </main>
    );
};

export default blogideas;
